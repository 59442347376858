import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { RecWrapper, RecBox, RecDes, FloatImgRight, FloatImgLeft, Stepper } from '../styles/projectInfoStyle.js'
import Layout from "../components/layout"
import pdffile from "../static/pdf_files/Aircraft_Stability_Control_Report.pdf"
const DownloadsPage = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     allFile(filter: { extension: { eq: "pdf" } }) {
  //       edges {
  //         node {
  //           name
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <Layout>
      <RecWrapper>
      <RecBox>
      <h1>Design and Analysis of an Empennage and Control Surfaces for Design Build Fly at Virginia Tech</h1>
      {/* <a href={pdffile} target="_blank">See the paper here.</a> */}

      <h2>See the technical paper here: <a href={pdffile} target="_blank">Report</a></h2>
      <p>Vist the <a href="https://github.com/rfish44" target="_blank">Github</a> repository for codes used in the paper!</p>

      </RecBox>
      </RecWrapper>    
    </Layout>
  )
}
export default DownloadsPage